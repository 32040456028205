export const FoC = ({ objects }) => {
  return objects.map((o) => (
    <a
      href={o.url}
      title={o.name}
      id={o.id}
      class="card cell box small-12 medium-4 large-3 text-left foc-card"
      key={o.id}
    >
      <div class="card-label">{o.name}</div>
    </a>
  ))
}

export const FoCWithSpecs = ({ objects, spec_filter }) => {
  return objects.map((o) => (
    <div class="cell foc-block" key={o.id}>
      <h2>
        <a class="foc-with-specs-link" href={o.url}>
          {o.name}
        </a>
      </h2>
      <ul class="specialization-link__grid">
        {o.specializations
          .filter((s) => (spec_filter ? spec_filter.includes(s.pk) : true))
          .map((s) => {
            return (
              <li key="{s.slug}" class="specialization-link__box">
                <a
                  class="specialization-link__link"
                  href={`${o.url}#${s.slug}`}
                  title={s.name}
                >
                  {s.name}
                </a>
              </li>
            )
          })}
      </ul>
    </div>
  ))
}
