/**
 *  Competence and reference/projects filtering logic
 */

import { render } from "preact"

import { AwEvents } from "./cards/events"
import { FoC, FoCWithSpecs } from "./cards/foc"
import { Jobs } from "./cards/jobs"
// cards
import { Projects } from "./cards/projects"

import { qs, qsa } from "../utils/dom"
import { Endpoint } from "../utils/endpoint.js"
import { Regions } from "./regions"

export class FilterView {
  constructor(view, type) {
    this.type = type
    this.view = view
    this.openClass = "open"
    this.expandedClass = "expanded"
    this.filterClassName = ".filter-fn"
    this.preFilterClassName = ".prefilter"
    this.pillSelector = "pill"
    this.panelSelector = "panel"
    this.minSearchWordLength = 3
    this.search = qs("[data-search]")
    this.searchPill = qs("#search-pill")
    this.collapsePill = qs("#collapse-pill")
    this.mobileCollapse = qs("#mobile-collapse")
    this.pills = qsa(this.pillSelector)
    this.form = qs("[data-filter-pills]")
    this.loadMoreButton = qs("[data-load-more]")
    this.isFetching = {
      pagination: false,
      filter: false,
    }
    this.caughtError = false
    this.loadMoreRequestTimestamp = null

    this.specializationFilter = null

    this.panels = qsa(this.panelSelector).map((p) => {
      return {
        element: p,
        prefilters: qsa(this.preFilterClassName, p).map((f) => {
          return {
            input: f,
            label: f.nextElementSibling,
          }
        }),
      }
    })

    const map = qs("[data-map]")
    if (map) this.map = new Regions(map)

    this.hitsCount = qsa("[data-hits]")

    this.listEl = qs("[data-list]")

    this.endpoint = new Endpoint(this.listEl.dataset.list)
    this.fetchedData = {
      objects: [],
    }

    this.updateLoadMoreButton()

    this.initFilters()

    // initially check set filter (in case of history.back())
    setTimeout(() => {
      this.filterChange()
    }, 300)

    // watch filter changes and propagate FilterMap
    qsa(this.filterClassName, this.view).forEach((el) => {
      el.addEventListener("change", () => {
        this.filterChange()
      })
    })

    if (this.search) {
      this.search.addEventListener("input", () => {
        if (
          this.search.value === "" ||
          this.search.value >= this.minSearchWordLength
        ) {
          this.filterChange()
        }
      })
    }

    // close panels on reset or submit clicks
    if (this.form) {
      this.form.addEventListener("reset", () => {
        this.closePills()
        this.searchPill && this.collapse()
        this.filterChange(true)
        if (this.map) this.map.reset()
      })

      this.form.addEventListener("submit", (e) => {
        e.preventDefault()
        this.closePills()
        this.filterChange()
      })
    }

    // open pill on click, expand and collapse search
    this.pills.forEach((pill) => {
      pill.addEventListener("click", this.pillActivation)
      pill.addEventListener("keyup", (e) => {
        e.preventDefault()
        const _keys = ["Enter", " "]
        if (_keys.indexOf(e.key) >= 0) {
          this.pillActivation(e)
        }
      })
      if (pill.id === "p-pill") {
        pill.click()
      }
    })

    this.mobileCollapse?.addEventListener("click", () => {
      this.mobileCollapse.parentNode.classList.toggle(this.expandedClass)
    })

    this.loadMoreButton.addEventListener("click", (e) => {
      e.preventDefault()
      this.loadMore()
    })

    // deactivate pills on outside clicks
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(this.pillSelector) &&
        !e.target.closest(this.panelSelector)
      ) {
        this.closePills()
      }
    })
  }

  /**
   * @param {Event} e
   */
  pillActivation = (e) => {
    e.preventDefault()
    const target = e.target?.closest(this.pillSelector)

    console.log(target)

    if (target) {
      if (target === this.searchPill) {
        this.expand()
      } else {
        if (target === this.collapsePill) {
          this.collapse()
        } else if (!target.classList.contains(this.openClass)) {
          this.openPill(target)
        } else if (target.classList.contains(this.openClass)) {
          this.closePills()
        }
      }
    }
  }

  expand() {
    if (this.collapsePill) {
      this.searchPill.classList.add(this.expandedClass)
      this.collapsePill.setAttribute("aria-expanded", false)
      this.setCollapsePillCnt()
      this.closePills()
    }
    this.search.focus()
  }

  collapse() {
    if (this.collapsePill) {
      this.searchPill.classList.remove(this.expandedClass)
      this.searchPill.classList.remove(this.openClass)
      this.collapsePill.setAttribute("aria-expanded", true)
    }
  }

  getListComponent(activeFilters) {
    switch (this.type) {
      case "project":
        return Projects
      case "foc":
        // biome-ignore lint: [lint/suspicious/noDoubleEquals]
        return !activeFilters.search && activeFilters.checks == 0
          ? FoC
          : FoCWithSpecs
      case "jobs":
        return Jobs
      case "events":
        return AwEvents
    }
  }

  getListProps() {
    switch (this.type) {
      case "project":
        return {
          objects: this.fetchedData.objects,
          filters: this.fetchedData.filters,
        }
      case "foc":
        return {
          objects: this.fetchedData.objects,
          filters: this.fetchedData.filters,
          spec_filter: this.fetchedData.spec_filter,
        }
      case "jobs":
        return {
          objects: this.fetchedData.objects,
          filters: this.fetchedData.filters,
          ll: this.listEl.dataset.ll,
        }
      case "events":
        return {
          objects: this.fetchedData.objects,
          filters: this.fetchedData.filters,
        }
    }
  }

  initFilters() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    urlParams.forEach((v, k) => {
      if (k === "search") {
        this.search.value = v
      } else if (k === "spe") {
        this.specializationFilter = v
      } else if (v !== "") {
        const el = qs(
          `${this.filterClassName}[value=${k === "r" ? `"${v}"` : v}]`,
        )
        if (el) {
          el.checked = true
        }
      }
    })
    //console.log(this.specializationFilter)
  }

  filterChange(reset = false) {
    let sWord = ""
    const checkedFilters = reset ? [] : this.getCheckedFilters()
    this.specializationFilter = reset ? null : this.specializationFilter
    if (!reset && this.search) {
      sWord = this.search.value
    }

    const query = this.prepareQuery(
      checkedFilters,
      sWord,
      this.specializationFilter,
    )

    // unset focus
    if (document.activeElement !== this.search) document.activeElement.blur()

    if (
      checkedFilters.length ||
      sWord.length >= this.minSearchWordLength ||
      this.specializationFilter
    ) {
      this.setPillCounter(checkedFilters, sWord)
      this.update(checkedFilters, sWord)
      history.pushState({}, null, `?${query.toString()}`)
    } else {
      history.pushState({}, null, location.pathname)
      this.setPillCounter()
      this.update()
    }
  }

  getCheckedFilters() {
    return this.panels
      ?.map((panel) => {
        return [...qsa(`${this.filterClassName}:checked`, panel.element)].map(
          (el) => {
            return {
              panel: panel.element.dataset.panel,
              key: el.value,
              label: el.title,
            }
          },
        )
      })
      .filter((m) => m.length)
      .flat()
  }

  precountFilters(filters) {
    this.panels.forEach((p) => {
      p.prefilters.forEach((f) => {
        if (
          filters[p.element.dataset.panel] &&
          filters[p.element.dataset.panel].length > 0
        ) {
          const match = filters[p.element.dataset.panel].find(
            (e) => e[0] === f.input.value,
          )
          if (match) {
            f.input.disabled = false
            f.label.dataset.cnt = match[2] ? match[2] : "0"
          } else if (!f.input.checked) {
            f.input.disabled = true
            f.label.dataset.cnt = "0"
          }
        }
      })
    })
  }

  setPillCounter(checked = [], sWord = "") {
    this.pills
      .filter((pill) => pill !== this.collapsePill)
      .forEach((pill) => {
        let cnt = 0
        checked.map((c) => {
          if (c.panel === pill.dataset.pill) {
            cnt++
          }
        })
        if (
          pill === this.searchPill &&
          sWord.length >= this.minSearchWordLength
        ) {
          cnt = "..."
        }
        const pillCnt = qs(".pill-cnt", pill)
        pillCnt && (pillCnt.dataset.cnt = cnt)
      })
    this.setMobileCollapsePillCnt()
  }

  setCollapsePillCnt() {
    if (this.collapsePill) {
      const pillCnt = qs(".pill-cnt", this.collapsePill)
      const cnt = qsa("pill:not(.search-pill, .collapse-pill) > .pill-cnt")
        .map((el) => {
          return el.dataset.cnt ? Number(el.dataset.cnt) : 0
        })
        .reduce((a, c) => {
          return a + c
        })
      pillCnt.dataset.cnt = cnt
    }
  }

  setMobileCollapsePillCnt() {
    if (this.mobileCollapse) {
      const pillCnt = qs(".pill-cnt", this.mobileCollapse)
      const cnt = qsa("pill:not(.collapse-pill) > .pill-cnt")
        .map((el) => {
          return el.dataset.cnt > 0 || el.dataset.cnt === "..." ? 1 : 0
        })
        .reduce((a, c) => {
          return a + c
        })
      pillCnt.dataset.cnt = cnt > 0 ? "..." : 0
    }
  }

  prepareQuery(filters, search, specializationFilter) {
    const query = new URLSearchParams()
    if (filters) {
      filters.forEach((f) => {
        query.append(f.panel, f.key)
      })
    }
    if (search) {
      query.append("search", search)
    }
    if (specializationFilter) {
      query.append("spe", specializationFilter)
    }

    return query
  }

  closePills() {
    this.pills.forEach((pill) => {
      const button = qs(this.buttonSelector, pill)
      if (button) {
        button.setAttribute("aria-expanded", false)
      }
      pill.classList.remove(this.openClass)
      if (this.search) this.search.blur()
      this.getPanel(pill)?.element.classList.remove(this.openClass)
    })
  }

  openPill(pill) {
    this.closePills()

    pill.setAttribute("aria-expanded", true)
    pill.classList.add(this.openClass)

    const panel = this.getPanel(pill)

    if (panel) {
      panel.element.classList.add(this.openClass)
    }

    const firstInput = qs("input", pill)
    if (firstInput) firstInput.focus()
  }

  getPanel(pill) {
    return this.panels.find(
      (panel) => panel.element.dataset.panel === pill.dataset.pill,
    )
  }

  async update(checked = [], sword = "") {
    this.toggleIsFetching("filter", true)
    this.toggleErrorMessage(false)

    // "cancel" load more request
    this.loadMoreRequestTimestamp = null

    try {
      const data = await this.endpoint.getData(
        this.prepareQuery(checked, sword, this.specializationFilter),
      )

      if (data) {
        this.fetchedData = data
        this.render()
      }
    } catch (_err) {
      this.toggleErrorMessage(true)
    }

    this.toggleIsFetching("filter", false)
  }

  async loadMore() {
    if (this.isFetching.filter || this.isFetching.pagination) return
    this.toggleIsFetching("pagination", true)
    this.toggleErrorMessage(false)

    const requestTimestamp = new Date().getTime()
    this.loadMoreRequestTimestamp = requestTimestamp

    const endpoint = new Endpoint(this.fetchedData.pagination.next)

    try {
      const data = await endpoint.getData()

      // ignore the response if the request timestamp is not the same anymore
      // e.g. canceled by the update method
      if (data && this.loadMoreRequestTimestamp === requestTimestamp) {
        this.fetchedData = {
          ...data,
          objects: [...this.fetchedData.objects, ...data.objects],
        }
        this.render()
      }
    } catch (_err) {
      this.toggleErrorMessage(true)
    }

    this.toggleIsFetching("pagination", false)
  }

  render() {
    const activeFilters = {
      search: this.search?.value,
      checks: this.getCheckedFilters(),
    }
    const ListComponent = this.getListComponent(activeFilters)
    const props = this.getListProps()

    render(<ListComponent {...props} />, this.listEl)

    this.precountFilters(props.filters)

    this.updateLoadMoreButton()

    this.hitsCount.forEach((hc) => {
      if (this.fetchedData.pagination?.n_total) {
        hc.dataset.hits = this.fetchedData.pagination.n_total
      } else {
        hc.dataset.hits = this.fetchedData.objects.length
      }
    })
  }

  updateLoadMoreButton() {
    if (this.fetchedData.pagination?.next) {
      this.loadMoreButton.parentElement.style.display = ""
    } else {
      this.loadMoreButton.parentElement.style.display = "none"
    }
  }

  toggleIsFetching(type, value) {
    this.isFetching[type] = value

    const fetchingTypeList = []

    for (const fetchingType in this.isFetching) {
      if (this.isFetching[fetchingType]) {
        fetchingTypeList.push(fetchingType)
      }
    }

    this.view.dataset.loading = fetchingTypeList.join(" ")

    if (this.isFetching.filter) {
      this.loadMoreButton.disabled = true
    } else {
      this.loadMoreButton.disabled = false
    }
  }

  toggleErrorMessage(force = null) {
    if (force !== null) {
      this.caughtError = force
    } else {
      this.caughtError = !this.caughtError
    }

    this.view.dataset.error = this.caughtError
  }
}
